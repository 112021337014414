import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from '../components/profile/profile.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { SignUpComponent } from '../components/sign-up/sign-up.component';
import { AuthGuard } from '../guards/auth.guard';

const appRoutes: Routes = [
	{ path: '', redirectTo: '/profile', pathMatch: 'full' },
	{ path: 'sign-in', component: SignInComponent },
	{ path: 'sign-up', component: SignUpComponent },
	{ path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
];

@NgModule({
	exports: [RouterModule],
	imports: [RouterModule.forRoot(appRoutes)],
	providers: [AuthGuard],
})
export class AppRoutingModule {}
