<div *ngIf="displayPhoneDiv">
	<h2>Phone Number</h2>
	<form
		[formGroup]="phoneNumberForm"
		(ngSubmit)="getPhoneNumber()">
		<input formControlName="phoneNumberInput" />
		<button type="submit">Sign in</button>
	</form>
</div>
<div *ngIf="displayCodeDiv">
	<h2>Verify code</h2>
	<form
		[formGroup]="verifyForm"
		(ngSubmit)="getCode()">
		<input formControlName="codeInput" />
		<button type="submit">Verify Code</button>
	</form>
</div>
<div id="recaptcha-container"></div>
