import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GoogleIdentityService } from '../services/identity.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private identityService: GoogleIdentityService, private router: Router) {}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (this.identityService.isSignedIn === null) {
			this.router.navigate(['sign-in']);
		}
		return true;
	}
}
