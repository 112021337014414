import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleIdentityService } from './services/identity.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	constructor(private identityService: GoogleIdentityService, private router: Router) {}

	ngOnInit(): void {
		this.identityService.getUser.subscribe(user => {
			if (user?.email) {
				this.identityService.setDock = true;
				this.router.navigate(['account']);
			} else {
				this.identityService.setDock = false;
				this.router.navigate(['sign-in']);
			}
		});
	}
}
