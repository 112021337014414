import { Component, OnInit } from '@angular/core';
import { ConfirmationResult, RecaptchaVerifier } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GoogleIdentityService } from 'src/app/services/identity.service';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
	public displayCodeDiv = false;
	public displayPhoneDiv = true;
	public phoneNumberForm = new FormGroup({ phoneNumberInput: new FormControl('') });
	public verifyForm = new FormGroup({ codeInput: new FormControl('') });
	private appVerifier!: RecaptchaVerifier;
	private verificationResults?: ConfirmationResult | null;

	constructor(private identityService: GoogleIdentityService, private router: Router, private messageService: MessageService) {}

	ngOnInit() {
		this.appVerifier = this.identityService.getRecaptcha();
	}

	async getPhoneNumber() {
		this.verificationResults = await this.identityService.signInWithPhone(this.appVerifier, this.phoneNumberForm.controls['phoneNumberInput'].value as string);
		if (this.verificationResults?.verificationId) {
			this.displayPhoneDiv = false;
			this.displayCodeDiv = true;
		}
	}
	getCode() {
		if (this.verificationResults) this.identityService.usePhoneCredentials(this.verificationResults, this.verifyForm.controls['codeInput'].value as string);
	}
}
