<div class="displayTable">
  <div class="displayTableCell">
    <div>
      <div class="authBlock">
        <h3>Sign Up</h3>
        <div class="formGroup">
          <input type="email" class="formControl" placeholder="Email Address" #userEmail required (keydown)=keyDownEmail($event)>
        </div>
        <div class="formGroup">
          <input type="password" class="formControl" placeholder="Password" #userPassword required (keydown)="keyDownPassword($event, userEmail.value, userPassword.value)">
        </div>
        <div class="formGroup">
          <p-button [style]="{'width':'100%'}" label="Sign up" type="button" (click)="SignUpButtonClick(userEmail.value, userPassword.value)"> </p-button>
        </div>
      </div>
      <div class="redirectToLogin">
        <span>Already have an account? <span class="redirect" routerLink="/sign-in">Log In</span></span>
      </div>
    </div>
  </div>